import React, { useEffect, useState } from 'react';
import { Hidden } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  AuthGridContainer, InnerGridContainer,
  LogoTypo, LogoGridContainer, InputsGridContainer, InputsPaper,
  InputsPaperContainer, InputsHeaderTypo, TypoDesc, TypoWrapper, SubTypo, FormWrapper, OgaAdminLogo,
  RatingStyled
} from './authContainer.styles';
import AuthBanner from '../../assets/images/authBanner.png';
import Register from './register';
import Login from './login';

import { useStateValue } from '../../providers/stateProvider';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './resetPassword';

const AuthContainer = () => {
  const [, dispatch] = Object.values(useStateValue());
  const [ogaDesc, setOgaDesc] = useState(true);
  const { pathname, state } = useLocation();
  const { becomeSeller, sellerState } = state || {};

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);

  const description = pathname === '/register' ? 'Sign Up on OGApharmacy' : 'Login to OGApharmacy';

  const returnComponent = () => {
    switch (pathname) {
      case '/':
        return <Login />;
      case '/register':
        return <Register setOgaDesc={setOgaDesc} beSeller={becomeSeller} sellerState={sellerState} />;
      case '/forgot-password':
        return <ForgotPassword />;
      default:
        return <ResetPassword />;
    }
  };

  const renderDescription = () => {
    if (pathname === '/') {
      return 'Welcome back to our marketplace, continue your procurement seamlessly';
    }
    if (ogaDesc) return 'Get started on OGApharmacy marketplace and start your procurement seamlessly.';
    return 'Complete your business information to continue on our OGApharmacy marketplace';
  };

  return (
    <AuthGridContainer container>
      <InnerGridContainer item container>
        <Hidden mdDown>
          <LogoGridContainer authBannerImg={AuthBanner} item container xs={12} md={6} lg={6}>
            <TypoWrapper>
              <LogoTypo variant="h5">
                Your reliable source for trusted pharmaceutical products.
              </LogoTypo>
              <TypoDesc>
                Continue running your procurement with OGApharmacy marketplace; Trusted by over 1,600 pharmaceutical stores.
              </TypoDesc>
            </TypoWrapper>
          </LogoGridContainer>
        </Hidden>
        <InputsGridContainer item container xs={12} md={6} lg={6}>
          <InputsPaper item container>
            <InputsPaperContainer item>
              <OgaAdminLogo />
              <InputsHeaderTypo variant="h6">
                {ogaDesc ? description : 'Create your Business'}
              </InputsHeaderTypo>
              <SubTypo>{renderDescription()}</SubTypo>
              <FormWrapper>{returnComponent()}</FormWrapper>
            </InputsPaperContainer>
          </InputsPaper>
        </InputsGridContainer>
      </InnerGridContainer>
    </AuthGridContainer>
  );
};

export default AuthContainer;
