import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#EE4223',
    color: '#fff',
    top: '3px',
    right: '6px',
    fontSize: '1.75rem',
    height: '32px',
    borderRadius: '18px',
    [theme.breakpoints.up('md')]: {
      fontSize: '.75rem',
      height: '20px',
      borderRadius: '10px',
    },
  },
}));

const SimpleBadge = ({
  children, value
}) => (
  <StyledBadge overlap="rectangular" badgeContent={value || 0}>
    { children }
  </StyledBadge>
);

SimpleBadge.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.number,
};

SimpleBadge.defaultProps = {
  value: 0
};

export default SimpleBadge;
